import { Form } from 'calidation';
import Page from '@lib/components/v2/Page';
import {
  IdSelectionContent,
  VerifyAdditionalDetailsContent,
  VerifyDetailsContent
} from '@VOI_FLOW_V2/components/Contents';
import React, { useState } from 'react';
import {
  ConfirmConsent,
  EditTooManyFields,
  ExpiredID,
  ExpiredIDBy2Years,
  IdNumberNotEditable
} from '@lib/components/v2/VerifyDetails/VerifyDetails.errors';
import { localizedString } from '@languages';
import {
  ENABLE_CONFIRM_AGE,
  ENABLE_ONE_DOC_CONDITION,
  HIDE_FOOTER_UPLOAD_SPINNER,
  ID_NUMBER_EDITABLE,
  VOI_FLOW_V2_NAME_MATCH_DOCS
} from '@spotMobileConfig';
import uniq from 'lodash/uniq';
import Modal from '@lib/components/v2/Modal';
import { defaultNameMatchDocs } from '@VOI_FLOW_V2/containers/NameMatch';
import { getAge } from '@lib/Utils';
import { isAgeEligible } from '@js/domain/ageEligibility';
import { AgeReview } from '@lib/components/v2/AgeReview';
import {
  isDocumentExpired,
  isEligibleForPassportExpiredLogic,
  isExpiredIdAllowed,
  isPassportExpiredBy2Years
} from '@lib/utils/checkExpiredDocument';
import PropTypes from 'prop-types';
import { DOCUMENTS } from '@lib/constants/documents';
import { isMedicare } from '@lib/utils/v2/document';

const nameMatchDocs = (VOI_FLOW_V2_NAME_MATCH_DOCS || defaultNameMatchDocs).filter(
  (doc) => doc.ocr
);

export const VerifyDetailsForm = ({
  onSubmit,
  onGoBack,
  setEditedFields,
  setFrontIDParams,
  handleRecapture,
  handleGoBack,
  onExtracted,

  state,
  setState,
  eligibleAge,
  idType,
  flowType,
  appConfig,
  editedFields,
  currentDocument,
  selectedDocumentList
}) => {
  const {
    currentDoc,
    data,
    extracted,
    loading,
    isUploading,
    progressBar,
    isLoading,
    pageTitle,
    useIdNumber
  } = state;

  const [hasFormErrors, setHasFormErrors] = useState(false);
  const [showTooManyEditsModal, setShowTooManyEditsModal] = useState(false);

  const { countryCode = '' } = data || {};

  const idTypeWithPatch = data?.cardType || idType;

  const handleChange = async (id, value) => {
    const editTooManyFieldsError = () => {
      const error = {
        component: EditTooManyFields,
        props: {
          buttons: [
            {
              label: localizedString('back'),
              variant: 'transparent',
              onClick: () => setState({ error: null })
            },
            {
              label: localizedString('recapture'),
              onClick: () => {
                handleRecapture(currentDoc);
              }
            }
          ]
        }
      };
      setState({ error });
    };

    // ID number not editable
    if (
      !ID_NUMBER_EDITABLE &&
      (id === 'idNumber' || id === 'licenceNumber' || id === 'passportNumber')
    ) {
      document.activeElement.blur(); // hide keyboard
      const error = {
        component: IdNumberNotEditable,
        props: {
          buttons: [
            {
              label: localizedString('back'),
              variant: 'outline',
              onClick: () => setState({ error: null })
            },
            {
              label: localizedString('recapture'),
              onClick: () => {
                handleRecapture(currentDoc);
              }
            }
          ]
        }
      };
      setState({ error });
      return;
    }

    if (id !== 'addressData' && !id.match(/^check/)) {
      let filtered = [];
      if ((value || '').toLowerCase() !== (extracted[id] || '').toLowerCase()) {
        filtered = uniq([...editedFields, id]);
      } else {
        filtered = editedFields.filter((key) => {
          return key !== id;
        });
      }

      // Field Edit limit
      if (
        !nameMatchDocs.find((doc) => doc.type === currentDoc.type) &&
        filtered.length > appConfig.field_edit_limit_count
      ) {
        editTooManyFieldsError();
        return;
      }

      setEditedFields(filtered);
    }

    setState(({ data, ...rest }) => {
      let dataToUpdate = {
        ...data,
        [id]: value
      };

      if (id === 'checkConfirm') {
        dataToUpdate = {
          ...dataToUpdate,
          dataCheckConsentAt: new Date().toISOString().replace('T', ' ').split('.')[0]
        };
      }

      if (['licenceNumber', 'passportNumber'].includes(id) && dataToUpdate.idNumber) {
        dataToUpdate.idNumber = value;
      }

      return { ...rest, data: dataToUpdate };
    });
  };

  const handleFormUpdate = ({ fields, isValid }) => {
    if (Object.keys(fields).length > 0) {
      setHasFormErrors(!isValid);
    }
  };

  const handleConfirm = async ({ isValid, dirty, fields }) => {
    const { token, currentDoc, data, hasFormErrors } = state;

    if (!isValid || hasFormErrors) {
      setState({ hasFormErrors: true });
      return;
    }

    if (data?.documentId) {
      currentDoc.documentId = data?.documentId;
    }
    setState({ currentDoc });
    onExtracted(currentDoc, token);

    if (checkShowAddress() && !data?.checkConfirm) {
      setState({
        error: {
          component: ConfirmConsent,
          props: {
            buttons: [
              {
                label: localizedString('ok'),
                onClick: () => setState({ error: null })
              }
            ]
          }
        }
      });
      return;
    }

    const { dateOfBirth, expiryDate, cardType, countryCode } = data;

    const age = getAge(dateOfBirth, true);
    if (ENABLE_CONFIRM_AGE && !isAgeEligible(age, eligibleAge) && !isMedicare(data?.cardType)) {
      setState({
        error: {
          component: AgeReview,
          props: {
            eligibleAge,
            onTryAgain: () => setState({ error: null })
          }
        }
      });
      return;
    }

    const isPassportExpiredBy2YearsFlag = isPassportExpiredBy2Years(expiryDate, {
      appConfig,
      cardType,
      countryCode
    });
    const isDocumentExpiredFlag = isDocumentExpired(expiryDate);
    const isExpired = isEligibleForPassportExpiredLogic(appConfig, cardType, countryCode)
      ? isPassportExpiredBy2YearsFlag
      : isDocumentExpiredFlag;

    if (isExpired && !isExpiredIdAllowed({ flowType, cardType })) {
      setState({
        error: {
          component: isPassportExpiredBy2YearsFlag ? ExpiredIDBy2Years : ExpiredID,
          props: {
            buttons: [
              {
                label: localizedString('back'),
                variant: 'transparent',
                onClick: () => {
                  setState({ error: null });
                }
              },
              {
                label: localizedString('recaptureDocument'),
                onClick: () => {
                  setFrontIDParams({});
                  handleRecapture(currentDoc);
                }
              }
            ]
          }
        }
      });
      return;
    }

    // eslint-disable-next-line consistent-return
    return onSubmit({
      isValid,
      dirty,
      fields,
      validateAddress: checkShowAddress(),
      changes: [...editedFields]
    });
  };

  const isSubmiting = loading || isUploading || isLoading;
  const footerButtons = [
    {
      label: localizedString('back'),
      disabled: isSubmiting,
      variant: 'transparent',
      onClick: handleGoBack
    }
  ];
  if (!isSubmiting || !HIDE_FOOTER_UPLOAD_SPINNER) {
    footerButtons.push({
      label: isSubmiting
        ? localizedString('loading')
        : localizedString('verifyDetails.FLOW_V2_VERIFY_DETAILS_ACTION_BUTTON'),
      type: 'submit',
      disabled: (checkShowAddress() && !data?.checkConfirm) || hasFormErrors || isSubmiting,
      variant: isSubmiting ? 'transparent' : 'secandary',
      loading: isSubmiting
    });
  }

  let title = pageTitle;
  if (ENABLE_ONE_DOC_CONDITION) {
    if (footerButtons.length >= 2) {
      footerButtons[1].label = 'Continue';
    }
    title = 'Check your I.D details';
  }

  return (
    <Form onUpdate={handleFormUpdate} onSubmit={handleConfirm}>
      <Page buttons={footerButtons} footerShadow forceFillViewPort={isLoading}>
        {isLoading && (
          <IdSelectionContent selectedCard={currentDoc} progressBar={progressBar} isLoading />
        )}
        {!isLoading && checkShowAddress() && (
          <VerifyDetailsContent
            {...data}
            idType={idTypeWithPatch}
            idTypeTitle={currentDoc.title}
            cardType={data?.cardType}
            flowType={flowType}
            pageTitle={title}
            countryOfIssue={data?.countryOfIssue}
            country={countryCode}
            useIdNumber={useIdNumber}
            onChange={handleChange}
            isSubmiting={isSubmiting}
          />
        )}
        {!isLoading && !checkShowAddress() && (
          <VerifyAdditionalDetailsContent
            data={data}
            idType={idTypeWithPatch}
            idTypeTitle={currentDoc?.title}
            flowType={flowType}
            pageTitle={title}
            useIdNumber={useIdNumber}
            onChange={handleChange}
            isSubmiting={isSubmiting}
          />
        )}
        {renderTooManyEditsModal()}
      </Page>
    </Form>
  );

  function renderTooManyEditsModal() {
    const confirmBtns = [
      {
        label: localizedString('tryAgain'),
        full: true,
        onClick: () => {
          setShowTooManyEditsModal(false);
          setFrontIDParams({});
          onGoBack();
        }
      }
    ];

    return (
      <Modal
        isOpen={showTooManyEditsModal}
        heading={localizedString('takeAnotherPhoto')}
        buttons={confirmBtns}
      >
        {localizedString('takeAnotherPhotoDesc1')}
        <br />
        <br />
        {localizedString('takeAnotherPhotoDesc2')}
      </Modal>
    );
  }

  function checkShowAddress() {
    if (ENABLE_ONE_DOC_CONDITION) {
      const { index } = currentDocument;
      return index <= 0;
    }
    const isDigitalOrDriverLicence = (type) =>
      type.match(/LICENCE/i) || type === DOCUMENTS.DIGITAL_DRIVER_LICENCE.documentType;

    const isDLSelected = selectedDocumentList.some((card) => isDigitalOrDriverLicence(card.type));

    const firsDoc = selectedDocumentList[0];

    if (!currentDoc?.type || !firsDoc?.type) {
      return false;
    }

    return isDLSelected
      ? isDigitalOrDriverLicence(currentDoc.type)
      : firsDoc.type === currentDoc.type;
  }
};

VerifyDetailsForm.propTypes = {
  onSubmit: PropTypes.func,
  onGoBack: PropTypes.func,
  setEditedFields: PropTypes.func,
  setFrontIDParams: PropTypes.func,
  handleRecapture: PropTypes.func,
  handleGoBack: PropTypes.func,
  onExtracted: PropTypes.func,

  state: PropTypes.any,
  setState: PropTypes.func,
  eligibleAge: PropTypes.any,
  idType: PropTypes.any,
  flowType: PropTypes.any,
  appConfig: PropTypes.any,
  editedFields: PropTypes.any,
  currentDocument: PropTypes.any,
  selectedDocumentList: PropTypes.any
};
